<template>
  <footer>
    <div class="contact-email">
      Email: <a href="mailto:service@popwatchapp.com">service@popwatchapp.com</a>
    </div>
    <div>Copyright © 2022 Save My Day LLC.</div>
    <div class="link-wrapper">
      <a class="link-policy" href="/privacy.html">Privacy Policy</a>
      <span class="split">|</span>
      <a class="link-terms" href="/terms.html">Terms of Service</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
footer {
  color: rgba(255, 255, 255, 0.45);
  text-align: center;
  line-height: 2em;
  margin: auto;
  font-size: 0.8rem;
}

footer a {
  color: rgba(255, 255, 255, 0.45);
  text-decoration: underline;
}

.contact-email {
  /* Add any specific styles you want for this element */
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  footer {
    font-size: 0.82rem;
    position: absolute;
    bottom: 1%;
    left: 0;
    right: 0;
  }

  .link-wrapper {
    display: inline-block;
    margin-left: 4em;
  }

  .link-wrapper .split {
    visibility: hidden;
  }
}
</style>